module.exports = [{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"3c9873da-5bd8-55bb-aa69-dd6e8456770f","name":"gatsby-remark-images","version":"3.1.26","pluginOptions":{"plugins":[],"maxWidth":1152,"showCaptions":true,"markdownCaptions":true,"wrapperStyle":"color:#718096;font-size:1rem;font-style:italic;text-align:center","withWebp":true},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":["onRenderBody"]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1152,"showCaptions":true,"markdownCaptions":true,"wrapperStyle":"color:#718096;font-size:1rem;font-style:italic;text-align:center","withWebp":true}},"gatsby-remark-embedder","gatsby-remark-smartypants","gatsby-remark-copy-linked-files"]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1152,"showCaptions":true,"markdownCaptions":true,"wrapperStyle":"color:#718096;font-size:1rem;font-style:italic;text-align:center","withWebp":true},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-59185932-1"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"GatsbyJS","start_url":"/","background_color":"#ffffff","theme_color":"#663399","display":"minimal-ui","icon":"content/assets/gatsby-icon.png"},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/vercel/path0/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
